import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import phaserGame from "../PhaserGame";
import Game from "../scenes/Game";

interface MicroBiologistState {
  microBiologistDialogOpen: boolean;
  microBiologistId: null | string;
  microBiologistUrl: null | string;
  urls: Map<string, string>;
}

const initialState: MicroBiologistState = {
  microBiologistDialogOpen: false,
  microBiologistId: null,
  microBiologistUrl: null,
  urls: new Map(),
};

export const microBiologistSlice = createSlice({
  name: "vendingMachine",
  initialState,
  reducers: {
    openMicrobiologistDialog: (state, action: PayloadAction<string>) => {
      state.microBiologistDialogOpen = true;
      state.microBiologistId = action.payload;
      const url = state.urls.get(action.payload);
      if (url) state.microBiologistUrl = url;
      const game = phaserGame.scene.keys.FirstLevel as Game;
      game.disableKeys();
    },
    closeMicrobiologistDialog: (state) => {
      const game = phaserGame.scene.keys.FirstLevel as Game;
      game.enableKeys();
      state.microBiologistDialogOpen = false;
      state.microBiologistId = null;
      state.microBiologistUrl = null;
    },
  },
});

export const { openMicrobiologistDialog, closeMicrobiologistDialog } =
  microBiologistSlice.actions;

export default microBiologistSlice.reducer;
