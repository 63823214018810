export const MAPS = {
  firstLevel: {
    file: "Supa_Lounge_alpha_v3.2.json",
    key: "first-level",
  },
  secondLevel: {
    file: "Corridor_1.json",
    key: "second-level",
  },
  thirdLevel: {
    file: "Lab.json",
    key: "third-level",
  },
} as const;
