export const assets = [
  {
    PLAYER: "111male",
    PLAYER_IDLE_DOWN: "111male_idle_down",
    PLAYER_IDLE_UP: "111male_idle_up",
    PLAYER_IDLE_LEFT: "111male_idle_left",
    PLAYER_IDLE_RIGHT: "111male_idle_right",
    PLAYER_RUN_RIGHT: "111male_run_right",
    PLAYER_RUN_UP: "111male_run_up",
    PLAYER_RUN_LEFT: "111male_run_left",
    PLAYER_RUN_DOWN: "111male_run_down",
  },
  {
    PLAYER: "112male",
    PLAYER_IDLE_DOWN: "112male_idle_down",
    PLAYER_IDLE_UP: "112male_idle_up",
    PLAYER_IDLE_LEFT: "112male_idle_left",
    PLAYER_IDLE_RIGHT: "112male_idle_right",
    PLAYER_RUN_RIGHT: "112male_run_right",
    PLAYER_RUN_UP: "112male_run_up",
    PLAYER_RUN_LEFT: "112male_run_left",
    PLAYER_RUN_DOWN: "112male_run_down",
  },
  {
    PLAYER: "113male",
    PLAYER_IDLE_DOWN: "113male_idle_down",
    PLAYER_IDLE_UP: "113male_idle_up",
    PLAYER_IDLE_LEFT: "113male_idle_left",
    PLAYER_IDLE_RIGHT: "113male_idle_right",
    PLAYER_RUN_RIGHT: "113male_run_right",
    PLAYER_RUN_UP: "113male_run_up",
    PLAYER_RUN_LEFT: "113male_run_left",
    PLAYER_RUN_DOWN: "113male_run_down",
  },
  {
    PLAYER: "121male",
    PLAYER_IDLE_DOWN: "121male_idle_down",
    PLAYER_IDLE_UP: "121male_idle_up",
    PLAYER_IDLE_LEFT: "121male_idle_left",
    PLAYER_IDLE_RIGHT: "121male_idle_right",
    PLAYER_RUN_RIGHT: "121male_run_right",
    PLAYER_RUN_UP: "121male_run_up",
    PLAYER_RUN_LEFT: "121male_run_left",
    PLAYER_RUN_DOWN: "121male_run_down",
  },
  {
    PLAYER: "122male",
    PLAYER_IDLE_DOWN: "122male_idle_down",
    PLAYER_IDLE_UP: "122male_idle_up",
    PLAYER_IDLE_LEFT: "122male_idle_left",
    PLAYER_IDLE_RIGHT: "122male_idle_right",
    PLAYER_RUN_RIGHT: "122male_run_right",
    PLAYER_RUN_UP: "122male_run_up",
    PLAYER_RUN_LEFT: "122male_run_left",
    PLAYER_RUN_DOWN: "122male_run_down",
  },
  {
    PLAYER: "123male",
    PLAYER_IDLE_DOWN: "123male_idle_down",
    PLAYER_IDLE_UP: "123male_idle_up",
    PLAYER_IDLE_LEFT: "123male_idle_left",
    PLAYER_IDLE_RIGHT: "123male_idle_right",
    PLAYER_RUN_RIGHT: "123male_run_right",
    PLAYER_RUN_UP: "123male_run_up",
    PLAYER_RUN_LEFT: "123male_run_left",
    PLAYER_RUN_DOWN: "123male_run_down",
  },
  {
    PLAYER: "131male",
    PLAYER_IDLE_DOWN: "131male_idle_down",
    PLAYER_IDLE_UP: "131male_idle_up",
    PLAYER_IDLE_LEFT: "131male_idle_left",
    PLAYER_IDLE_RIGHT: "131male_idle_right",
    PLAYER_RUN_RIGHT: "131male_run_right",
    PLAYER_RUN_UP: "131male_run_up",
    PLAYER_RUN_LEFT: "131male_run_left",
    PLAYER_RUN_DOWN: "131male_run_down",
  },
  {
    PLAYER: "132male",
    PLAYER_IDLE_DOWN: "132male_idle_down",
    PLAYER_IDLE_UP: "132male_idle_up",
    PLAYER_IDLE_LEFT: "132male_idle_left",
    PLAYER_IDLE_RIGHT: "132male_idle_right",
    PLAYER_RUN_RIGHT: "132male_run_right",
    PLAYER_RUN_UP: "132male_run_up",
    PLAYER_RUN_LEFT: "132male_run_left",
    PLAYER_RUN_DOWN: "132male_run_down",
  },
  {
    PLAYER: "133male",
    PLAYER_IDLE_DOWN: "133male_idle_down",
    PLAYER_IDLE_UP: "133male_idle_up",
    PLAYER_IDLE_LEFT: "133male_idle_left",
    PLAYER_IDLE_RIGHT: "133male_idle_right",
    PLAYER_RUN_RIGHT: "133male_run_right",
    PLAYER_RUN_UP: "133male_run_up",
    PLAYER_RUN_LEFT: "133male_run_left",
    PLAYER_RUN_DOWN: "133male_run_down",
  },
  {
    PLAYER: "211male",
    PLAYER_IDLE_DOWN: "211male_idle_down",
    PLAYER_IDLE_UP: "211male_idle_up",
    PLAYER_IDLE_LEFT: "211male_idle_left",
    PLAYER_IDLE_RIGHT: "211male_idle_right",
    PLAYER_RUN_RIGHT: "211male_run_right",
    PLAYER_RUN_UP: "211male_run_up",
    PLAYER_RUN_LEFT: "211male_run_left",
    PLAYER_RUN_DOWN: "211male_run_down",
  },
  {
    PLAYER: "212male",
    PLAYER_IDLE_DOWN: "212male_idle_down",
    PLAYER_IDLE_UP: "212male_idle_up",
    PLAYER_IDLE_LEFT: "212male_idle_left",
    PLAYER_IDLE_RIGHT: "212male_idle_right",
    PLAYER_RUN_RIGHT: "212male_run_right",
    PLAYER_RUN_UP: "212male_run_up",
    PLAYER_RUN_LEFT: "212male_run_left",
    PLAYER_RUN_DOWN: "212male_run_down",
  },
  {
    PLAYER: "213male",
    PLAYER_IDLE_DOWN: "213male_idle_down",
    PLAYER_IDLE_UP: "213male_idle_up",
    PLAYER_IDLE_LEFT: "213male_idle_left",
    PLAYER_IDLE_RIGHT: "213male_idle_right",
    PLAYER_RUN_RIGHT: "213male_run_right",
    PLAYER_RUN_UP: "213male_run_up",
    PLAYER_RUN_LEFT: "213male_run_left",
    PLAYER_RUN_DOWN: "213male_run_down",
  },
  {
    PLAYER: "231male",
    PLAYER_IDLE_DOWN: "231male_idle_down",
    PLAYER_IDLE_UP: "231male_idle_up",
    PLAYER_IDLE_LEFT: "231male_idle_left",
    PLAYER_IDLE_RIGHT: "231male_idle_right",
    PLAYER_RUN_RIGHT: "231male_run_right",
    PLAYER_RUN_UP: "231male_run_up",
    PLAYER_RUN_LEFT: "231male_run_left",
    PLAYER_RUN_DOWN: "231male_run_down",
  },
  {
    PLAYER: "232male",
    PLAYER_IDLE_DOWN: "232male_idle_down",
    PLAYER_IDLE_UP: "232male_idle_up",
    PLAYER_IDLE_LEFT: "232male_idle_left",
    PLAYER_IDLE_RIGHT: "232male_idle_right",
    PLAYER_RUN_RIGHT: "232male_run_right",
    PLAYER_RUN_UP: "232male_run_up",
    PLAYER_RUN_LEFT: "232male_run_left",
    PLAYER_RUN_DOWN: "232male_run_down",
  },
  {
    PLAYER: "233male",
    PLAYER_IDLE_DOWN: "233male_idle_down",
    PLAYER_IDLE_UP: "233male_idle_up",
    PLAYER_IDLE_LEFT: "233male_idle_left",
    PLAYER_IDLE_RIGHT: "233male_idle_right",
    PLAYER_RUN_RIGHT: "233male_run_right",
    PLAYER_RUN_UP: "233male_run_up",
    PLAYER_RUN_LEFT: "233male_run_left",
    PLAYER_RUN_DOWN: "233male_run_down",
  },
  {
    PLAYER: "311male",
    PLAYER_IDLE_DOWN: "311male_idle_down",
    PLAYER_IDLE_UP: "311male_idle_up",
    PLAYER_IDLE_LEFT: "311male_idle_left",
    PLAYER_IDLE_RIGHT: "311male_idle_right",
    PLAYER_RUN_RIGHT: "311male_run_right",
    PLAYER_RUN_UP: "311male_run_up",
    PLAYER_RUN_LEFT: "311male_run_left",
    PLAYER_RUN_DOWN: "311male_run_down",
  },
  {
    PLAYER: "312male",
    PLAYER_IDLE_DOWN: "312male_idle_down",
    PLAYER_IDLE_UP: "312male_idle_up",
    PLAYER_IDLE_LEFT: "312male_idle_left",
    PLAYER_IDLE_RIGHT: "312male_idle_right",
    PLAYER_RUN_RIGHT: "312male_run_right",
    PLAYER_RUN_UP: "312male_run_up",
    PLAYER_RUN_LEFT: "312male_run_left",
    PLAYER_RUN_DOWN: "312male_run_down",
  },
  {
    PLAYER: "313male",
    PLAYER_IDLE_DOWN: "313male_idle_down",
    PLAYER_IDLE_UP: "313male_idle_up",
    PLAYER_IDLE_LEFT: "313male_idle_left",
    PLAYER_IDLE_RIGHT: "313male_idle_right",
    PLAYER_RUN_RIGHT: "313male_run_right",
    PLAYER_RUN_UP: "313male_run_up",
    PLAYER_RUN_LEFT: "313male_run_left",
    PLAYER_RUN_DOWN: "313male_run_down",
  },
  {
    PLAYER: "321male",
    PLAYER_IDLE_DOWN: "321male_idle_down",
    PLAYER_IDLE_UP: "321male_idle_up",
    PLAYER_IDLE_LEFT: "321male_idle_left",
    PLAYER_IDLE_RIGHT: "321male_idle_right",
    PLAYER_RUN_RIGHT: "321male_run_right",
    PLAYER_RUN_UP: "321male_run_up",
    PLAYER_RUN_LEFT: "321male_run_left",
    PLAYER_RUN_DOWN: "321male_run_down",
  },
  {
    PLAYER: "322male",
    PLAYER_IDLE_DOWN: "322male_idle_down",
    PLAYER_IDLE_UP: "322male_idle_up",
    PLAYER_IDLE_LEFT: "322male_idle_left",
    PLAYER_IDLE_RIGHT: "322male_idle_right",
    PLAYER_RUN_RIGHT: "322male_run_right",
    PLAYER_RUN_UP: "322male_run_up",
    PLAYER_RUN_LEFT: "322male_run_left",
    PLAYER_RUN_DOWN: "322male_run_down",
  },
  {
    PLAYER: "323male",
    PLAYER_IDLE_DOWN: "323male_idle_down",
    PLAYER_IDLE_UP: "323male_idle_up",
    PLAYER_IDLE_LEFT: "323male_idle_left",
    PLAYER_IDLE_RIGHT: "323male_idle_right",
    PLAYER_RUN_RIGHT: "323male_run_right",
    PLAYER_RUN_UP: "323male_run_up",
    PLAYER_RUN_LEFT: "323male_run_left",
    PLAYER_RUN_DOWN: "323male_run_down",
  },
  {
    PLAYER: "331male",
    PLAYER_IDLE_DOWN: "331male_idle_down",
    PLAYER_IDLE_UP: "331male_idle_up",
    PLAYER_IDLE_LEFT: "331male_idle_left",
    PLAYER_IDLE_RIGHT: "331male_idle_right",
    PLAYER_RUN_RIGHT: "331male_run_right",
    PLAYER_RUN_UP: "331male_run_up",
    PLAYER_RUN_LEFT: "331male_run_left",
    PLAYER_RUN_DOWN: "331male_run_down",
  },
  {
    PLAYER: "332male",
    PLAYER_IDLE_DOWN: "332male_idle_down",
    PLAYER_IDLE_UP: "332male_idle_up",
    PLAYER_IDLE_LEFT: "332male_idle_left",
    PLAYER_IDLE_RIGHT: "332male_idle_right",
    PLAYER_RUN_RIGHT: "332male_run_right",
    PLAYER_RUN_UP: "332male_run_up",
    PLAYER_RUN_LEFT: "332male_run_left",
    PLAYER_RUN_DOWN: "332male_run_down",
  },
  {
    PLAYER: "333male",
    PLAYER_IDLE_DOWN: "333male_idle_down",
    PLAYER_IDLE_UP: "333male_idle_up",
    PLAYER_IDLE_LEFT: "333male_idle_left",
    PLAYER_IDLE_RIGHT: "333male_idle_right",
    PLAYER_RUN_RIGHT: "333male_run_right",
    PLAYER_RUN_UP: "333male_run_up",
    PLAYER_RUN_LEFT: "333male_run_left",
    PLAYER_RUN_DOWN: "333male_run_down",
  },
  {
    PLAYER: "111female",
    PLAYER_IDLE_DOWN: "111female_idle_down",
    PLAYER_IDLE_UP: "111female_idle_up",
    PLAYER_IDLE_LEFT: "111female_idle_left",
    PLAYER_IDLE_RIGHT: "111female_idle_right",
    PLAYER_RUN_RIGHT: "111female_run_right",
    PLAYER_RUN_UP: "111female_run_up",
    PLAYER_RUN_LEFT: "111female_run_left",
    PLAYER_RUN_DOWN: "111female_run_down",
  },
  {
    PLAYER: "112female",
    PLAYER_IDLE_DOWN: "112female_idle_down",
    PLAYER_IDLE_UP: "112female_idle_up",
    PLAYER_IDLE_LEFT: "112female_idle_left",
    PLAYER_IDLE_RIGHT: "112female_idle_right",
    PLAYER_RUN_RIGHT: "112female_run_right",
    PLAYER_RUN_UP: "112female_run_up",
    PLAYER_RUN_LEFT: "112female_run_left",
    PLAYER_RUN_DOWN: "112female_run_down",
  },
  {
    PLAYER: "113female",
    PLAYER_IDLE_DOWN: "113female_idle_down",
    PLAYER_IDLE_UP: "113female_idle_up",
    PLAYER_IDLE_LEFT: "113female_idle_left",
    PLAYER_IDLE_RIGHT: "113female_idle_right",
    PLAYER_RUN_RIGHT: "113female_run_right",
    PLAYER_RUN_UP: "113female_run_up",
    PLAYER_RUN_LEFT: "113female_run_left",
    PLAYER_RUN_DOWN: "113female_run_down",
  },
  {
    PLAYER: "121female",
    PLAYER_IDLE_DOWN: "121female_idle_down",
    PLAYER_IDLE_UP: "121female_idle_up",
    PLAYER_IDLE_LEFT: "121female_idle_left",
    PLAYER_IDLE_RIGHT: "121female_idle_right",
    PLAYER_RUN_RIGHT: "121female_run_right",
    PLAYER_RUN_UP: "121female_run_up",
    PLAYER_RUN_LEFT: "121female_run_left",
    PLAYER_RUN_DOWN: "121female_run_down",
  },
  {
    PLAYER: "122female",
    PLAYER_IDLE_DOWN: "122female_idle_down",
    PLAYER_IDLE_UP: "122female_idle_up",
    PLAYER_IDLE_LEFT: "122female_idle_left",
    PLAYER_IDLE_RIGHT: "122female_idle_right",
    PLAYER_RUN_RIGHT: "122female_run_right",
    PLAYER_RUN_UP: "122female_run_up",
    PLAYER_RUN_LEFT: "122female_run_left",
    PLAYER_RUN_DOWN: "122female_run_down",
  },
  {
    PLAYER: "123female",
    PLAYER_IDLE_DOWN: "123female_idle_down",
    PLAYER_IDLE_UP: "123female_idle_up",
    PLAYER_IDLE_LEFT: "123female_idle_left",
    PLAYER_IDLE_RIGHT: "123female_idle_right",
    PLAYER_RUN_RIGHT: "123female_run_right",
    PLAYER_RUN_UP: "123female_run_up",
    PLAYER_RUN_LEFT: "123female_run_left",
    PLAYER_RUN_DOWN: "123female_run_down",
  },
  {
    PLAYER: "131female",
    PLAYER_IDLE_DOWN: "131female_idle_down",
    PLAYER_IDLE_UP: "131female_idle_up",
    PLAYER_IDLE_LEFT: "131female_idle_left",
    PLAYER_IDLE_RIGHT: "131female_idle_right",
    PLAYER_RUN_RIGHT: "131female_run_right",
    PLAYER_RUN_UP: "131female_run_up",
    PLAYER_RUN_LEFT: "131female_run_left",
    PLAYER_RUN_DOWN: "131female_run_down",
  },
  {
    PLAYER: "132female",
    PLAYER_IDLE_DOWN: "132female_idle_down",
    PLAYER_IDLE_UP: "132female_idle_up",
    PLAYER_IDLE_LEFT: "132female_idle_left",
    PLAYER_IDLE_RIGHT: "132female_idle_right",
    PLAYER_RUN_RIGHT: "132female_run_right",
    PLAYER_RUN_UP: "132female_run_up",
    PLAYER_RUN_LEFT: "132female_run_left",
    PLAYER_RUN_DOWN: "132female_run_down",
  },
  {
    PLAYER: "133female",
    PLAYER_IDLE_DOWN: "133female_idle_down",
    PLAYER_IDLE_UP: "133female_idle_up",
    PLAYER_IDLE_LEFT: "133female_idle_left",
    PLAYER_IDLE_RIGHT: "133female_idle_right",
    PLAYER_RUN_RIGHT: "133female_run_right",
    PLAYER_RUN_UP: "133female_run_up",
    PLAYER_RUN_LEFT: "133female_run_left",
    PLAYER_RUN_DOWN: "133female_run_down",
  },
  {
    PLAYER: "211female",
    PLAYER_IDLE_DOWN: "211female_idle_down",
    PLAYER_IDLE_UP: "211female_idle_up",
    PLAYER_IDLE_LEFT: "211female_idle_left",
    PLAYER_IDLE_RIGHT: "211female_idle_right",
    PLAYER_RUN_RIGHT: "211female_run_right",
    PLAYER_RUN_UP: "211female_run_up",
    PLAYER_RUN_LEFT: "211female_run_left",
    PLAYER_RUN_DOWN: "211female_run_down",
  },
  {
    PLAYER: "212female",
    PLAYER_IDLE_DOWN: "212female_idle_down",
    PLAYER_IDLE_UP: "212female_idle_up",
    PLAYER_IDLE_LEFT: "212female_idle_left",
    PLAYER_IDLE_RIGHT: "212female_idle_right",
    PLAYER_RUN_RIGHT: "212female_run_right",
    PLAYER_RUN_UP: "212female_run_up",
    PLAYER_RUN_LEFT: "212female_run_left",
    PLAYER_RUN_DOWN: "212female_run_down",
  },
  {
    PLAYER: "213female",
    PLAYER_IDLE_DOWN: "213female_idle_down",
    PLAYER_IDLE_UP: "213female_idle_up",
    PLAYER_IDLE_LEFT: "213female_idle_left",
    PLAYER_IDLE_RIGHT: "213female_idle_right",
    PLAYER_RUN_RIGHT: "213female_run_right",
    PLAYER_RUN_UP: "213female_run_up",
    PLAYER_RUN_LEFT: "213female_run_left",
    PLAYER_RUN_DOWN: "213female_run_down",
  },
  {
    PLAYER: "222female",
    PLAYER_IDLE_DOWN: "222female_idle_down",
    PLAYER_IDLE_UP: "222female_idle_up",
    PLAYER_IDLE_LEFT: "222female_idle_left",
    PLAYER_IDLE_RIGHT: "222female_idle_right",
    PLAYER_RUN_RIGHT: "222female_run_right",
    PLAYER_RUN_UP: "222female_run_up",
    PLAYER_RUN_LEFT: "222female_run_left",
    PLAYER_RUN_DOWN: "222female_run_down",
  },
  {
    PLAYER: "231female",
    PLAYER_IDLE_DOWN: "231female_idle_down",
    PLAYER_IDLE_UP: "231female_idle_up",
    PLAYER_IDLE_LEFT: "231female_idle_left",
    PLAYER_IDLE_RIGHT: "231female_idle_right",
    PLAYER_RUN_RIGHT: "231female_run_right",
    PLAYER_RUN_UP: "231female_run_up",
    PLAYER_RUN_LEFT: "231female_run_left",
    PLAYER_RUN_DOWN: "231female_run_down",
  },
  {
    PLAYER: "232female",
    PLAYER_IDLE_DOWN: "232female_idle_down",
    PLAYER_IDLE_UP: "232female_idle_up",
    PLAYER_IDLE_LEFT: "232female_idle_left",
    PLAYER_IDLE_RIGHT: "232female_idle_right",
    PLAYER_RUN_RIGHT: "232female_run_right",
    PLAYER_RUN_UP: "232female_run_up",
    PLAYER_RUN_LEFT: "232female_run_left",
    PLAYER_RUN_DOWN: "232female_run_down",
  },
  {
    PLAYER: "233female",
    PLAYER_IDLE_DOWN: "233female_idle_down",
    PLAYER_IDLE_UP: "233female_idle_up",
    PLAYER_IDLE_LEFT: "233female_idle_left",
    PLAYER_IDLE_RIGHT: "233female_idle_right",
    PLAYER_RUN_RIGHT: "233female_run_right",
    PLAYER_RUN_UP: "233female_run_up",
    PLAYER_RUN_LEFT: "233female_run_left",
    PLAYER_RUN_DOWN: "233female_run_down",
  },
  {
    PLAYER: "311female",
    PLAYER_IDLE_DOWN: "311female_idle_down",
    PLAYER_IDLE_UP: "311female_idle_up",
    PLAYER_IDLE_LEFT: "311female_idle_left",
    PLAYER_IDLE_RIGHT: "311female_idle_right",
    PLAYER_RUN_RIGHT: "311female_run_right",
    PLAYER_RUN_UP: "311female_run_up",
    PLAYER_RUN_LEFT: "311female_run_left",
    PLAYER_RUN_DOWN: "311female_run_down",
  },
  {
    PLAYER: "312female",
    PLAYER_IDLE_DOWN: "312female_idle_down",
    PLAYER_IDLE_UP: "312female_idle_up",
    PLAYER_IDLE_LEFT: "312female_idle_left",
    PLAYER_IDLE_RIGHT: "312female_idle_right",
    PLAYER_RUN_RIGHT: "312female_run_right",
    PLAYER_RUN_UP: "312female_run_up",
    PLAYER_RUN_LEFT: "312female_run_left",
    PLAYER_RUN_DOWN: "312female_run_down",
  },
  {
    PLAYER: "313female",
    PLAYER_IDLE_DOWN: "313female_idle_down",
    PLAYER_IDLE_UP: "313female_idle_up",
    PLAYER_IDLE_LEFT: "313female_idle_left",
    PLAYER_IDLE_RIGHT: "313female_idle_right",
    PLAYER_RUN_RIGHT: "313female_run_right",
    PLAYER_RUN_UP: "313female_run_up",
    PLAYER_RUN_LEFT: "313female_run_left",
    PLAYER_RUN_DOWN: "313female_run_down",
  },
  {
    PLAYER: "321female",
    PLAYER_IDLE_DOWN: "321female_idle_down",
    PLAYER_IDLE_UP: "321female_idle_up",
    PLAYER_IDLE_LEFT: "321female_idle_left",
    PLAYER_IDLE_RIGHT: "321female_idle_right",
    PLAYER_RUN_RIGHT: "321female_run_right",
    PLAYER_RUN_UP: "321female_run_up",
    PLAYER_RUN_LEFT: "321female_run_left",
    PLAYER_RUN_DOWN: "321female_run_down",
  },
  {
    PLAYER: "322female",
    PLAYER_IDLE_DOWN: "322female_idle_down",
    PLAYER_IDLE_UP: "322female_idle_up",
    PLAYER_IDLE_LEFT: "322female_idle_left",
    PLAYER_IDLE_RIGHT: "322female_idle_right",
    PLAYER_RUN_RIGHT: "322female_run_right",
    PLAYER_RUN_UP: "322female_run_up",
    PLAYER_RUN_LEFT: "322female_run_left",
    PLAYER_RUN_DOWN: "322female_run_down",
  },
  {
    PLAYER: "323female",
    PLAYER_IDLE_DOWN: "323female_idle_down",
    PLAYER_IDLE_UP: "323female_idle_up",
    PLAYER_IDLE_LEFT: "323female_idle_left",
    PLAYER_IDLE_RIGHT: "323female_idle_right",
    PLAYER_RUN_RIGHT: "323female_run_right",
    PLAYER_RUN_UP: "323female_run_up",
    PLAYER_RUN_LEFT: "323female_run_left",
    PLAYER_RUN_DOWN: "323female_run_down",
  },
  {
    PLAYER: "331female",
    PLAYER_IDLE_DOWN: "331female_idle_down",
    PLAYER_IDLE_UP: "331female_idle_up",
    PLAYER_IDLE_LEFT: "331female_idle_left",
    PLAYER_IDLE_RIGHT: "331female_idle_right",
    PLAYER_RUN_RIGHT: "331female_run_right",
    PLAYER_RUN_UP: "331female_run_up",
    PLAYER_RUN_LEFT: "331female_run_left",
    PLAYER_RUN_DOWN: "331female_run_down",
  },
  {
    PLAYER: "332female",
    PLAYER_IDLE_DOWN: "332female_idle_down",
    PLAYER_IDLE_UP: "332female_idle_up",
    PLAYER_IDLE_LEFT: "332female_idle_left",
    PLAYER_IDLE_RIGHT: "332female_idle_right",
    PLAYER_RUN_RIGHT: "332female_run_right",
    PLAYER_RUN_UP: "332female_run_up",
    PLAYER_RUN_LEFT: "332female_run_left",
    PLAYER_RUN_DOWN: "332female_run_down",
  },
  {
    PLAYER: "333female",
    PLAYER_IDLE_DOWN: "333female_idle_down",
    PLAYER_IDLE_UP: "333female_idle_up",
    PLAYER_IDLE_LEFT: "333female_idle_left",
    PLAYER_IDLE_RIGHT: "333female_idle_right",
    PLAYER_RUN_RIGHT: "333female_run_right",
    PLAYER_RUN_UP: "333female_run_up",
    PLAYER_RUN_LEFT: "333female_run_left",
    PLAYER_RUN_DOWN: "333female_run_down",
  },
] as const;
