import Phaser from "phaser";
import { BackgroundMode } from "../../../types/BackgroundMode";

export default class Background extends Phaser.Scene {
  private backdropKey!: string;

  constructor() {
    super("background");
  }

  create(data: { backgroundMode: BackgroundMode }) {
    const sceneHeight = this.cameras.main.height;
    const sceneWidth = this.cameras.main.width;

    // set texture of images based on the background mode
    if (data.backgroundMode === BackgroundMode.DAY) {
      this.backdropKey = "backdrop_day";
      this.cameras.main.setBackgroundColor("#c6eefc");
    } else {
      this.backdropKey = "backdrop_night";
      this.cameras.main.setBackgroundColor("#2c4464");
    }

    // Add backdrop image
    const backdropImage = this.add.image(
      sceneWidth / 2,
      sceneHeight / 2,
      this.backdropKey
    );
    const scale = Math.max(
      sceneWidth / backdropImage.width,
      sceneHeight / backdropImage.height
    );
    backdropImage.setScale(scale).setScrollFactor(0);
  }

  update(t: number, dt: number) {
    this.physics.world.wrap(500);
  }
}
