import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import phaserGame from "../PhaserGame";
import Game from "../scenes/Game";

interface SpecimenDealerState {
  specimenDealerDialogOpen: boolean;
  specimenDealerId: null | string;
  specimenDealerUrl: null | string;
  urls: Map<string, string>;
}

const initialState: SpecimenDealerState = {
  specimenDealerDialogOpen: false,
  specimenDealerId: null,
  specimenDealerUrl: null,
  urls: new Map(),
};

export const specimenDealerSlice = createSlice({
  name: "specimenDealer",
  initialState,
  reducers: {
    openSpecimenDealerDialog: (state, action: PayloadAction<string>) => {
      state.specimenDealerDialogOpen = true;
      state.specimenDealerId = action.payload;
      const url = state.urls.get(action.payload);
      if (url) state.specimenDealerUrl = url;
      const game = phaserGame.scene.keys.FirstLevel as Game;
      game.disableKeys();
    },
    closeSpecimenDealerDialog: (state) => {
      const game = phaserGame.scene.keys.FirstLevel as Game;
      game.enableKeys();
      state.specimenDealerDialogOpen = false;
      state.specimenDealerId = null;
      state.specimenDealerUrl = null;
    },
  },
});

export const { openSpecimenDealerDialog, closeSpecimenDealerDialog } =
  specimenDealerSlice.actions;

export default specimenDealerSlice.reducer;
