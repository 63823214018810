import Game from "../scenes/Game";

export class Npc extends Phaser.Physics.Arcade.Sprite {
  public scene: Game;
  private textGameObject: Phaser.GameObjects.Text;

  constructor(scene: Game, x: number, y: number, text: string) {
    super(scene, x, y, "microbiologist", 0);
    this.scene = scene;
    this.scene.physics.add.existing(this);
    this.scene.add.existing(this);

    this.anims.play("microbiologist_run");

    this.setDepth(5);
    this.textGameObject = this.scene.add.text(0, 0, text, {
      align: "center",
      fontSize: "24px",
    });
    this.textGameObject.setWordWrapWidth(150);
    this.textGameObject.setPosition(
      this.x + (this.width - this.textGameObject.width) / 2,
      this.y - this.textGameObject.height - 10
    );
    this.textGameObject.setAlpha(0).setDepth(1000);
    this.setImmovable(true);
  }

  public talk = () => {
    this.textGameObject.setAlpha(1);
    this.scene.time.addEvent({
      delay: 2000,
      callback: this.hideText,
      callbackScope: this,
    });
  };

  private hideText = () => {
    this.textGameObject.setAlpha(0);
  };
}
