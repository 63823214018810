import Phaser from "phaser";
import Game from "./scenes/Game";
import Background from "./scenes/Background";
import Bootstrap from "./scenes/Bootstrap";
import Bootscene from "./scenes/BootScene";
import FirstLevel from "./scenes/FirstLevel";
import SecondLevel from "./scenes/SecondLevel";
import ThirdLevel from "./scenes/ThirdLevel";
import RexUIPlugin from "phaser3-rex-plugins/templates/ui/ui-plugin.js";

const config: Phaser.Types.Core.GameConfig = {
  type: Phaser.AUTO,
  parent: "phaser-container",
  dom: {
    createContainer: true,
  },
  backgroundColor: "#93cbee",
  pixelArt: true, // Prevent pixel art from becoming blurred when scaled.
  scale: {
    mode: Phaser.Scale.ScaleModes.RESIZE,
    width: window.innerWidth,
    height: window.innerHeight,
  },
  physics: {
    default: "arcade",
    arcade: {
      gravity: { y: 0 },
      debug: false,
    },
  },
  autoFocus: true,
  scene: [
    Bootscene,
    Bootstrap,
    Background,
    Game,
    FirstLevel,
    SecondLevel,
    ThirdLevel,
  ],
  plugins: {
    scene: [
      {
        key: "rexUI",
        plugin: RexUIPlugin,
        mapping: "rexUI",
      },
    ],
  },
};

const phaserGame = new Phaser.Game(config);

(window as any).game = phaserGame;

export default phaserGame;
