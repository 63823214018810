import Phaser from "phaser";
import { assets } from "../constants/assets";

export const createCharacterAnims = (
  anims: Phaser.Animations.AnimationManager
) => {
  const animsFrameRate = 15;

  // male character ---------------------------------------------
  anims.create({
    key: "male_idle_down",
    frames: anims.generateFrameNames("male", {
      start: 7,
      end: 7,
    }),
    repeat: -1,
    frameRate: animsFrameRate * 0.6,
  });

  anims.create({
    key: "male_idle_up",
    frames: anims.generateFrameNames("male", {
      start: 1,
      end: 1,
    }),
    repeat: -1,
    frameRate: animsFrameRate * 0.6,
  });

  anims.create({
    key: "male_idle_left",
    frames: anims.generateFrameNames("male", {
      start: 9,
      end: 9,
    }),
    repeat: -1,
    frameRate: animsFrameRate * 0.6,
  });

  anims.create({
    key: "male_idle_right",
    frames: anims.generateFrameNames("male", {
      start: 13,
      end: 13,
    }),
    repeat: -1,
    frameRate: animsFrameRate * 0.6,
  });

  anims.create({
    key: "male_run_right",
    frames: anims.generateFrameNames("male", {
      start: 12,
      end: 15,
    }),
    repeat: -1,
    frameRate: animsFrameRate,
  });

  anims.create({
    key: "male_run_up",
    frames: anims.generateFrameNames("male", {
      start: 0,
      end: 2,
    }),
    repeat: -1,
    frameRate: animsFrameRate,
  });

  anims.create({
    key: "male_run_left",
    frames: anims.generateFrameNames("male", {
      start: 11,
      end: 8,
    }),
    repeat: -1,
    frameRate: animsFrameRate,
  });

  anims.create({
    key: "male_run_down",
    frames: anims.generateFrameNames("male", {
      start: 3,
      end: 7,
    }),
    repeat: -1,
    frameRate: animsFrameRate,
  });

  // anims.create({
  //   key: 'male_sit_down',
  //   frames: anims.generateFrameNames('male', {
  //     start: 48,
  //     end: 48,
  //   }),
  //   repeat: 0,
  //   frameRate: animsFrameRate,
  // })

  // anims.create({
  //   key: 'male_sit_left',
  //   frames: anims.generateFrameNames('male', {
  //     start: 49,
  //     end: 49,
  //   }),
  //   repeat: 0,
  //   frameRate: animsFrameRate,
  // })

  // anims.create({
  //   key: 'male_sit_right',
  //   frames: anims.generateFrameNames('male', {
  //     start: 50,
  //     end: 50,
  //   }),
  //   repeat: 0,
  //   frameRate: animsFrameRate,
  // })

  // anims.create({
  //   key: 'male_sit_up',
  //   frames: anims.generateFrameNames('male', {
  //     start: 51,
  //     end: 51,
  //   }),
  //   repeat: 0,
  //   frameRate: animsFrameRate,
  // })
  // male character ---------------------------------------------

  // female character ---------------------------------------------
  anims.create({
    key: "female_idle_down",
    frames: anims.generateFrameNames("female", {
      start: 7,
      end: 7,
    }),
    repeat: -1,
    frameRate: animsFrameRate * 0.6,
  });

  anims.create({
    key: "female_idle_up",
    frames: anims.generateFrameNames("female", {
      start: 1,
      end: 1,
    }),
    repeat: -1,
    frameRate: animsFrameRate * 0.6,
  });

  anims.create({
    key: "female_idle_left",
    frames: anims.generateFrameNames("female", {
      start: 9,
      end: 9,
    }),
    repeat: -1,
    frameRate: animsFrameRate * 0.6,
  });

  anims.create({
    key: "female_idle_right",
    frames: anims.generateFrameNames("female", {
      start: 13,
      end: 13,
    }),
    repeat: -1,
    frameRate: animsFrameRate * 0.6,
  });

  anims.create({
    key: "female_run_right",
    frames: anims.generateFrameNames("female", {
      start: 12,
      end: 15,
    }),
    repeat: -1,
    frameRate: animsFrameRate,
  });

  anims.create({
    key: "female_run_up",
    frames: anims.generateFrameNames("female", {
      start: 0,
      end: 2,
    }),
    repeat: -1,
    frameRate: animsFrameRate,
  });

  anims.create({
    key: "female_run_left",
    frames: anims.generateFrameNames("female", {
      start: 11,
      end: 8,
    }),
    repeat: -1,
    frameRate: animsFrameRate,
  });

  anims.create({
    key: "female_run_down",
    frames: anims.generateFrameNames("female", {
      start: 3,
      end: 7,
    }),
    repeat: -1,
    frameRate: animsFrameRate,
  });

  // anims.create({
  //   key: 'female_sit_down',
  //   frames: anims.generateFrameNames('female', {
  //     start: 48,
  //     end: 48,
  //   }),
  //   repeat: 0,
  //   frameRate: animsFrameRate,
  // })

  // anims.create({
  //   key: 'female_sit_left',
  //   frames: anims.generateFrameNames('female', {
  //     start: 49,
  //     end: 49,
  //   }),
  //   repeat: 0,
  //   frameRate: animsFrameRate,
  // })

  // anims.create({
  //   key: 'female_sit_right',
  //   frames: anims.generateFrameNames('female', {
  //     start: 50,
  //     end: 50,
  //   }),
  //   repeat: 0,
  //   frameRate: animsFrameRate,
  // })

  // anims.create({
  //   key: 'female_sit_up',
  //   frames: anims.generateFrameNames('female', {
  //     start: 51,
  //     end: 51,
  //   }),
  //   repeat: 0,
  //   frameRate: animsFrameRate,
  // })
  // female character ---------------------------------------------

  // npc character ---------------------------------------------
  anims.create({
    key: "microbiologist_idle_down",
    frames: anims.generateFrameNames("microbiologist", {
      start: 0,
      end: 0,
    }),
    delay: 500,
    repeat: -1,
    frameRate: animsFrameRate,
  });

  anims.create({
    key: "microbiologist_run",
    frames: anims.generateFrameNames("microbiologist", {
      start: 1,
      end: 2,
    }),
    duration: 1000,
    delay: 500,
    repeat: -1,
    frameRate: 2.5,
  });
  // npc character ---------------------------------------------

  // npc specimen  character ---------------------------------------------
  anims.create({
    key: "specimendealer_idle_down",
    frames: anims.generateFrameNames("specimendealer", {
      start: 0,
      end: 0,
    }),
    delay: 500,
    repeat: -1,
    frameRate: animsFrameRate,
  });

  anims.create({
    key: "specimendealer_run",
    frames: anims.generateFrameNames("specimendealer", {
      start: 0,
      end: 1,
    }),
    duration: 1000,
    delay: 500,
    repeat: -1,
    frameRate: 2.5,
  });
  // npc specimendealer character ---------------------------------------------

  // npc token trader character ---------------------------------------------
  anims.create({
    key: "supatokentrader_idle_down",
    frames: anims.generateFrameNames("supatokentrader", {
      start: 0,
      end: 0,
    }),
    delay: 500,
    repeat: -1,
    frameRate: animsFrameRate,
  });

  anims.create({
    key: "supatokentrader_run",
    frames: anims.generateFrameNames("supatokentrader", {
      start: 0,
      end: 1,
    }),
    duration: 1000,
    delay: 500,
    repeat: -1,
    frameRate: 2.5,
  });
  // npc token trader character ---------------------------------------------

  // auto_male character ---------------------------------------------
  assets.map((item) => {
    anims.create({
      key: item.PLAYER_IDLE_DOWN,
      frames: anims.generateFrameNames(item.PLAYER, {
        start: 4,
        end: 4,
      }),
      repeat: -1,
      frameRate: animsFrameRate * 0.6,
    });

    anims.create({
      key: item.PLAYER_IDLE_UP,
      frames: anims.generateFrameNames(item.PLAYER, {
        start: 0,
        end: 0,
      }),
      repeat: -1,
      frameRate: animsFrameRate * 0.6,
    });

    anims.create({
      key: item.PLAYER_IDLE_LEFT,
      frames: anims.generateFrameNames(item.PLAYER, {
        start: 8,
        end: 8,
      }),
      repeat: -1,
      frameRate: animsFrameRate * 0.6,
    });

    anims.create({
      key: item.PLAYER_IDLE_RIGHT,
      frames: anims.generateFrameNames(item.PLAYER, {
        start: 14,
        end: 14,
      }),
      repeat: -1,
      frameRate: animsFrameRate * 0.6,
    });

    anims.create({
      key: item.PLAYER_RUN_RIGHT,
      frames: anims.generateFrameNames(item.PLAYER, {
        start: 12,
        end: 15,
      }),
      repeat: -1,
      frameRate: animsFrameRate,
    });

    anims.create({
      key: item.PLAYER_RUN_UP,
      frames: anims.generateFrameNames(item.PLAYER, {
        start: 0,
        end: 3,
      }),
      repeat: -1,
      frameRate: animsFrameRate,
    });

    anims.create({
      key: item.PLAYER_RUN_LEFT,
      frames: anims.generateFrameNames(item.PLAYER, {
        start: 8,
        end: 11,
      }),
      repeat: -1,
      frameRate: animsFrameRate,
    });

    anims.create({
      key: item.PLAYER_RUN_DOWN,
      frames: anims.generateFrameNames(item.PLAYER, {
        start: 4,
        end: 7,
      }),
      repeat: -1,
      frameRate: animsFrameRate,
    });
  });
  // auto_male character ---------------------------------------------
};
