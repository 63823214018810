import React from "react";
import styled from "styled-components";

import { useAppSelector } from "./hooks";

import RoomSelectionDialog from "./components/RoomSelectionDialog";
import LoginDialog from "./components/LoginDialog";
import ComputerDialog from "./components/ComputerDialog";
import WhiteboardDialog from "./components/WhiteboardDialog";
import VideoConnectionDialog from "./components/VideoConnectionDialog";
import Chat from "./components/Chat";
import HelperButtonGroup from "./components/HelperButtonGroup";
import MicroBiologistDialog from "./components/MicroBiologistDialog";
import SpecimenDealerDialog from "./components/SpecimenDealerDialog";
import TokenTraderDialog from "./components/TokenTraderDialog";

const Backdrop = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`;

function App() {
  const loggedIn = useAppSelector((state) => state.user.loggedIn);
  const computerDialogOpen = useAppSelector(
    (state) => state.computer.computerDialogOpen
  );
  const whiteboardDialogOpen = useAppSelector(
    (state) => state.whiteboard.whiteboardDialogOpen
  );
  const videoConnected = useAppSelector((state) => state.user.videoConnected);
  const roomJoined = useAppSelector((state) => state.room.roomJoined);
  const microBiologistDialogOpen = useAppSelector(
    (state) => state.microBiologist.microBiologistDialogOpen
  );
  const specimenDealerDialogOpen = useAppSelector(
    (state) => state.specimenDealer.specimenDealerDialogOpen
  );
  const tokenTraderDialogOpen = useAppSelector(
    (state) => state.tokenTrader.tokenTraderDialogOpen
  );

  let ui: JSX.Element;
  if (loggedIn) {
    if (computerDialogOpen) {
      /* Render ComputerDialog if user is using a computer. */
      ui = <ComputerDialog />;
    } else if (whiteboardDialogOpen) {
      /* Render WhiteboardDialog if user is using a whiteboard. */
      ui = <WhiteboardDialog />;
    } else if (microBiologistDialogOpen) {
      /* Render WhiteboardDialog if user is using a vending machine. */
      ui = <MicroBiologistDialog />;
    } else if (specimenDealerDialogOpen) {
      /* Render WhiteboardDialog if user is using a vending machine. */
      ui = <SpecimenDealerDialog />;
    } else if (tokenTraderDialogOpen) {
      /* Render WhiteboardDialog if user is using a vending machine. */
      ui = <TokenTraderDialog />;
    } else {
      ui = (
        /* Render Chat or VideoConnectionDialog if no dialogs are opened. */
        <>
          <Chat />
          {/* Render VideoConnectionDialog if user is not connected to a webcam. */}
          {!videoConnected && <VideoConnectionDialog />}
        </>
      );
    }
  } else if (roomJoined) {
    /* Render LoginDialog if not logged in but selected a room. */
    ui = <LoginDialog />;
  } else {
    /* Render RoomSelectionDialog if yet selected a room. */
    ui = <RoomSelectionDialog />;
  }

  return (
    <Backdrop>
      {ui}
      {/* Render HelperButtonGroup if no dialogs are opened. */}
      {!computerDialogOpen && !whiteboardDialogOpen && <HelperButtonGroup />}
    </Backdrop>
  );
}

export default App;
