import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { sanitizeId } from "../util";
import { BackgroundMode } from "../../../types/BackgroundMode";

import phaserGame from "../PhaserGame";
import Bootstrap from "../scenes/Bootstrap";

export function getInitialBackgroundMode() {
  const currentHour = new Date().getHours();
  return currentHour > 6 && currentHour <= 18
    ? BackgroundMode.DAY
    : BackgroundMode.NIGHT;
}

export const userSlice = createSlice({
  name: "user",
  initialState: {
    backgroundMode: getInitialBackgroundMode(),
    sessionId: "",
    videoConnected: false,
    backgroundMusic: false,
    loggedIn: false,
    textureName: "",
    playerX: 0,
    playerY: 0,
    namePlayer: "",
    playerNameMap: new Map<string, string>(),
  },
  reducers: {
    toggleBackgroundMode: (state) => {
      const newMode =
        state.backgroundMode === BackgroundMode.DAY
          ? BackgroundMode.NIGHT
          : BackgroundMode.DAY;
      state.backgroundMode = newMode;
      const bootstrap = phaserGame.scene.keys.bootstrap as Bootstrap;
      bootstrap.changeBackgroundMode(newMode);
    },
    toogleBackgroundMusicOn: (state) => {
      phaserGame.sound.add("backgroundMusic", {
        volume: 0.2,
        loop: true,
      });
      phaserGame.sound.pauseOnBlur = false;
      const currentlyPlaying = phaserGame.sound.get("backgroundMusic");
      if (!state.backgroundMusic && currentlyPlaying) {
        state.backgroundMusic = true;
        currentlyPlaying.play();
      }
    },
    toogleBackgroundMusicOff: (state) => {
      const currentlyPlaying = phaserGame.sound.get("backgroundMusic");
      state.backgroundMusic = false;
      currentlyPlaying.stop();
    },
    setSessionId: (state, action: PayloadAction<string>) => {
      state.sessionId = action.payload;
    },
    setVideoConnected: (state, action: PayloadAction<boolean>) => {
      state.videoConnected = action.payload;
    },
    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload;
    },
    setTextureName: (state, action: PayloadAction<string>) => {
      state.textureName = action.payload;
    },
    setPlayerX: (state, action: PayloadAction<number>) => {
      state.playerX = action.payload;
    },
    setPlayerY: (state, action: PayloadAction<number>) => {
      state.playerY = action.payload;
    },
    setNamePlayer: (state, action: PayloadAction<string>) => {
      state.namePlayer = action.payload;
    },
    setPlayerNameMap: (
      state,
      action: PayloadAction<{ id: string; name: string }>
    ) => {
      state.playerNameMap.set(
        sanitizeId(action.payload.id),
        action.payload.name
      );
    },
    removePlayerNameMap: (state, action: PayloadAction<string>) => {
      state.playerNameMap.delete(sanitizeId(action.payload));
    },
  },
});

export const {
  toggleBackgroundMode,
  toogleBackgroundMusicOn,
  toogleBackgroundMusicOff,
  setSessionId,
  setVideoConnected,
  setLoggedIn,
  setPlayerNameMap,
  removePlayerNameMap,
  setTextureName,
  setNamePlayer,
  setPlayerX,
  setPlayerY,
} = userSlice.actions;

export default userSlice.reducer;
