import Phaser from "phaser";
import Network from "../services/Network";
import { BackgroundMode } from "../../../types/BackgroundMode";
import store from "../stores";
import { setRoomJoined } from "../stores/RoomStore";
import { SCENES } from "../constants/scenes";

export default class Bootstrap extends Phaser.Scene {
  network!: Network;

  constructor() {
    super("bootstrap");
  }

  init() {
    this.network = new Network();
  }

  create() {
    this.launchBackground(store.getState().user.backgroundMode);
  }

  private launchBackground(backgroundMode: BackgroundMode) {
    this.scene.launch("background", { backgroundMode });
  }

  launchGame() {
    // this.network.webRTC?.checkPreviousPermission();
    this.scene.launch(SCENES.FIRST_LEVEL, {
      network: this.network,
    });

    // update Redux state
    store.dispatch(setRoomJoined(true));
  }

  changeBackgroundMode(backgroundMode: BackgroundMode) {
    this.scene.stop("background");
    this.launchBackground(backgroundMode);
  }
}
