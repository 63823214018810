import Phaser from "phaser";

import { debugDraw } from "../utils/debug";
import { createCharacterAnims } from "../anims/CharacterAnims";

import Item from "../items/Item";
import Chair from "../items/Chair";
import Computer from "../items/Computer";
import Whiteboard from "../items/Whiteboard";
import Microbiologist from "../items/Microbiologist";
import SpecimenDealer from "../items/SpecimenDealer";
import TokenTrader from "../items/TokenTrader";
import "../characters/MyPlayer";
import "../characters/OtherPlayer";
import MyPlayer from "../characters/MyPlayer";
import OtherPlayer from "../characters/OtherPlayer";
import PlayerSelector from "../characters/PlayerSelector";
import Network from "../services/Network";
import { IPlayer } from "../../../types/IOfficeState";
import { PlayerBehavior } from "../../../types/PlayerBehavior";
import { ItemType } from "../../../types/Items";

import store from "../stores";
import { setFocused, setShowChat } from "../stores/ChatStore";

import { Npc } from "../gameobjects/Npc";
import { NpcSpecimenDealer } from "../gameobjects/NpcSpecimenDealer";
import { NpcTokenTrader } from "../gameobjects/NpcTokenTrader";
import { MAP_CONTENT_KEYS } from "../constants/map-content-keys";
import { MAPS } from "../constants/maps";
import { setPlayerX, setPlayerY } from "../stores/UserStore";

interface InterSceneData {
  comesFrom: string;
}
export default class Game extends Phaser.Scene {
  network!: Network;
  private cursors!: Phaser.Types.Input.Keyboard.CursorKeys;
  private keyE!: Phaser.Input.Keyboard.Key;
  private keyR!: Phaser.Input.Keyboard.Key;
  private map!: Phaser.Tilemaps.Tilemap;
  myPlayer!: MyPlayer;
  private playerSelector!: Phaser.GameObjects.Zone;
  private otherPlayers!: Phaser.Physics.Arcade.Group;
  private otherPlayerMap = new Map<string, OtherPlayer>();
  computerMap = new Map<string, Computer>();
  private whiteboardMap = new Map<string, Whiteboard>();
  public npcs: Npc[];
  public npcSpecimenDealer: NpcSpecimenDealer[];
  public npcTokenTrader: NpcTokenTrader[];
  private microBiologistsMap = new Map<string, Microbiologist>();
  private specimenDealerMap = new Map<string, SpecimenDealer>();
  private tokenTraderMap = new Map<string, TokenTrader>();
  public mapKey: string;

  constructor(key: string, mapKey: string) {
    console.log("key")
    console.log(key)
    console.log("keyend")

    super(key);
    this.mapKey = mapKey;
    this.npcs = [];
    this.npcSpecimenDealer = [];
    this.npcTokenTrader = [];
  }

  protected init(data: any) {
    this.createLayer(data);

    this.physics.world.setBounds(
      0,
      0,
      this.map.widthInPixels,
      this.map.heightInPixels
    );

    // npcs
    const npcsMapObjects = this.map.objects.find((o) => o.name === "npcs");
    if (npcsMapObjects) {
      const npcs: any = npcsMapObjects ? npcsMapObjects.objects : [];
      this.npcs = npcs.map((npc: any) => {
        return new Npc(this, npc.x, npc.y, npc.properties.message);
      });
    }

    const levelChangerObjectLayer = this.map.objects.find(
      (o) => o.name === MAP_CONTENT_KEYS.objects.ZONES_CORRIDOR
    );
    // CORRIDOR
    if (levelChangerObjectLayer) {
      levelChangerObjectLayer.objects.map((o: any) => {
        const zone = this.add.zone(o.x, o.y, o.width, o.height);
        this.physics.add.existing(zone);
        this.physics.add.overlap(zone, this.playerSelector, () => {
          // console.log("scene corridor: ", o.properties);
          if (o.properties[3].value === "2") {
            // 550,
            // 1100,
            store.dispatch(setPlayerX(550));
            store.dispatch(setPlayerY(1100));
          } else {
            // 7100,
            // 2500,
            store.dispatch(setPlayerX(7100));
            store.dispatch(setPlayerY(2500));
          }
          this.scene.start(o.properties[1].value, {
            comesFrom: this.scene.key,
            network: this.network,
          });
        });
      });
    }

    const levelChangerObjectLoungeLayer = this.map.objects.find(
      (o) => o.name === MAP_CONTENT_KEYS.objects.ZONES_LOUNGE
    );
    // LOUNGE
    if (levelChangerObjectLoungeLayer) {
      levelChangerObjectLoungeLayer.objects.map((o: any) => {
        const zone = this.add.zone(o.x, o.y, o.width, o.height);
        this.physics.add.existing(zone);
        this.physics.add.overlap(zone, this.playerSelector, () => {
          // 4600
          // 2100
          // console.log("scene lounge: ", o.properties);
          this.scene.start(o.properties[1].value, {
            comesFrom: this.scene.key,
            network: this.network,
          });
        });
      });
    }

    const levelChangerObjectLabLayer = this.map.objects.find(
      (o) => o.name === MAP_CONTENT_KEYS.objects.ZONES_LAB
    );
    // LAB
    if (levelChangerObjectLabLayer) {
      levelChangerObjectLabLayer.objects.map((o: any) => {
        const zone = this.add.zone(o.x, o.y, o.width, o.height);
        this.physics.add.existing(zone);
        this.physics.add.overlap(zone, this.playerSelector, () => {
          // console.log("scene lab: ", o.properties);
          this.scene.start(o.properties[1].value, {
            comesFrom: this.scene.key,
            network: this.network,
          });
        });
      });
    }

    // this.createAudio();
    this.initCamera();
    this.registerKeys();
  }

  registerKeys() {
    this.cursors = this.input.keyboard.createCursorKeys();
    // maybe we can have a dedicated method for adding keys if more keys are needed in the future
    this.keyE = this.input.keyboard.addKey("E");
    this.keyR = this.input.keyboard.addKey("R");
    this.input.keyboard.disableGlobalCapture();
    this.input.keyboard.on("keydown-ENTER", (event) => {
      store.dispatch(setShowChat(true));
      store.dispatch(setFocused(true));
    });
    this.input.keyboard.on("keydown-ESC", (event) => {
      store.dispatch(setShowChat(false));
    });
  }

  disableKeys() {
    this.input.keyboard.enabled = false;
  }

  enableKeys() {
    this.input.keyboard.enabled = true;
  }

  createAudio() {
    this.sound.add("walk", { loop: true });
  }

  createLayer(data: { network: Network }) {
    if (!data.network) {
      throw new Error("server instance missing");
    } else {
      this.network = data.network;
    }

    createCharacterAnims(this.anims);

    this.map = this.make.tilemap({ key: this.mapKey });
    const FloorAndGround = this.map.addTilesetImage(
      "PropSprites(2)",
      "tiles_wall_two"
    );

    console.log("scene cek: ", this.mapKey);

    const groundLayer = this.map.createLayer("Floor Tiles", FloorAndGround);
    groundLayer.setCollisionByProperty({ collides: true });

    const dataState = store.getState().user;

    switch (this.mapKey) {
      case MAPS.firstLevel.key:
        const shadowHalfTile = this.map.addTilesetImage(
          "Shadow_half_tile",
          "Shadow_half_tile"
        );

        const shadowQuarterTile = this.map.addTilesetImage(
          "Shadow_quartertile",
          "Shadow_quartertile"
        );

        // cautionTape
        const cautionTapeLayer = this.map.createLayer(
          "Caution Tape",
          FloorAndGround
        );
        cautionTapeLayer.setCollisionByProperty({ collides: true });
        // cautionTapeLayer.setCollisionBetween(0, 100, true);

        // cautionTape2
        const cautionTape2Layer = this.map.createLayer(
          "Caution Tape 2",
          FloorAndGround
        );
        cautionTape2Layer.setCollisionByProperty({ collides: true });

        // shadow half
        const shadowHalfLayer = this.map.createLayer("Shadow", shadowHalfTile);
        // shadowHalfLayer.setCollisionByProperty({ collides: true });

        // shadow quarter
        const shadowQuarterLayer = this.map.createLayer(
          "Shadow 2",
          shadowQuarterTile
        );
        // shadowQuarterLayer.setCollisionByProperty({ collides: true });

        // WallTilesConferenceRoom4
        const wallTilesConferenceRoom4Layer = this.map.createLayer(
          "Wall Tiles Conference ROom 4",
          FloorAndGround
        );
        wallTilesConferenceRoom4Layer.setCollisionByProperty({
          collides: true,
        });

        // WallTilesConferenceRoom3
        const wallTilesConferenceRoom3Layer = this.map.createLayer(
          "Wall Tiles Conference Room 3",
          FloorAndGround
        );
        wallTilesConferenceRoom3Layer.setCollisionByProperty({
          collides: true,
        });

        // WallTilesConferenceRoom
        const wallTilesConferenceRoomLayer = this.map.createLayer(
          "Wall Tiles Conference Room",
          FloorAndGround
        );
        wallTilesConferenceRoomLayer.setCollisionByProperty({ collides: true });

        // ConferenceRoomAssets
        const conferenceRoomAssetsLayer = this.map.createLayer(
          "Conference Room Assets",
          FloorAndGround
        );
        conferenceRoomAssetsLayer.setCollisionByProperty({ collides: true });

        // ConferenceRoomAssets2
        const conferenceRoomAssets2Layer = this.map.createLayer(
          "Conference Room Assets 2",
          FloorAndGround
        );
        conferenceRoomAssets2Layer.setCollisionByProperty({ collides: true });

        // MarketplaceWallTiles2
        const marketplaceWallTiles2Layer = this.map.createLayer(
          "Marketplace Wall Tiles 2",
          FloorAndGround
        );
        marketplaceWallTiles2Layer.setCollisionByProperty({ collides: true });

        // MarketplaceWallTiles
        const marketplaceWallTilesLayer = this.map.createLayer(
          "Marketplace Wall Tiles",
          FloorAndGround
        );
        marketplaceWallTilesLayer.setCollisionByProperty({ collides: true });

        // Marketplace
        const marketplaceAssetsLayer = this.map.createLayer(
          "Marketplace Assets",
          FloorAndGround
        );
        marketplaceAssetsLayer.setCollisionByProperty({ collides: true });

        // Marketplace
        const marketplaceAssets2Layer = this.map.createLayer(
          "Marketplace Assets 2",
          FloorAndGround
        );
        marketplaceAssets2Layer.setCollisionByProperty({ collides: true });

        // Tile Layer 13
        const tileLayer13 = this.map.createLayer(
          "Tile Layer 13",
          FloorAndGround
        );
        tileLayer13.setCollisionByProperty({ collides: true });

        // Tile Layer 14
        const tileLayer14 = this.map.createLayer(
          "Tile Layer 14",
          FloorAndGround
        );
        tileLayer14.setCollisionByProperty({ collides: true });

        // Lounge Tables
        const loungeTablesLayer = this.map.createLayer(
          "Lounge Tables",
          FloorAndGround
        );
        loungeTablesLayer.setCollisionByProperty({ collides: true });

        // Tile Layer 12
        const tileLayer12 = this.map.createLayer(
          "Tile Layer 12",
          FloorAndGround
        );
        tileLayer12.setCollisionByProperty({ collides: true });

        // Tile Layer 16
        const tileLayer16 = this.map.createLayer(
          "Tile Layer 16",
          FloorAndGround
        );
        tileLayer16.setCollisionByProperty({ collides: true });

        // debugDraw(groundLayer, this);
        // dataState.playerX ? dataState.playerX : 4600,
        // dataState.playerY ? dataState.playerX : 2100,

        this.myPlayer = this.add.myPlayer(
          12100,
          2500,
          dataState.textureName ? dataState.textureName : "male",
          this.network.mySessionId
        );
        this.playerSelector = new PlayerSelector(this, 0, 0, 256, 512);

        // don't go out of the map
        this.physics.world.bounds.width = this.map.widthInPixels;
        this.physics.world.bounds.height = this.map.heightInPixels;
        this.myPlayer.setCollideWorldBounds(true);

        // import chair objects from Tiled map to Phaser
        // const chairs = this.physics.add.staticGroup({ classType: Chair })
        // const chairLayer = this.map.getObjectLayer('Chair')
        // chairLayer.objects.forEach((chairObj) => {
        //   const item = this.addObjectFromTiled(chairs, chairObj, 'chairs', 'chair') as Chair
        //   // custom properties[0] is the object direction specified in Tiled
        //   item.itemDirection = chairObj.properties[0].value
        // })

        // import computers objects from Tiled map to Phaser
        // const computers = this.physics.add.staticGroup({ classType: Computer })
        // const computerLayer = this.map.getObjectLayer('Computer')
        // computerLayer.objects.forEach((obj, i) => {
        //   const item = this.addObjectFromTiled(computers, obj, 'computers', 'computer') as Computer
        //   item.setDepth(item.y + item.height * 0.27)
        //   const id = `${i}`
        //   item.id = id
        //   this.computerMap.set(id, item)
        // })

        // import whiteboards objects from Tiled map to Phaser
        // const whiteboards = this.physics.add.staticGroup({ classType: Whiteboard })
        // const whiteboardLayer = this.map.getObjectLayer('Whiteboard')
        // whiteboardLayer.objects.forEach((obj, i) => {
        //   const item = this.addObjectFromTiled(
        //     whiteboards,
        //     obj,
        //     'whiteboards',
        //     'whiteboard'
        //   ) as Whiteboard
        //   const id = `${i}`
        //   item.id = id
        //   this.whiteboardMap.set(id, item)
        // })

        // import vending machine objects from Tiled map to Phaser
        // const vendingMachines = this.physics.add.staticGroup({ classType: VendingMachine })
        // const vendingMachineLayer = this.map.getObjectLayer('VendingMachine')
        // vendingMachineLayer.objects.forEach((obj, i) => {
        //   this.addObjectFromTiled(vendingMachines, obj, 'vendingmachines', 'vendingmachine')
        // })

        // import speciment dealer objects from Tiled map to Phaser
        const specimenDealers = this.physics.add.staticGroup({
          classType: SpecimenDealer,
        });
        const specimenDealerLayer =
          this.map.getObjectLayer("npcSpecimenDealer");
        specimenDealerLayer.objects.forEach((obj, i) => {
          const item = this.addObjectFromPoint(
            specimenDealers,
            obj,
            "specimendealer",
            "npcSpecimenDealer"
          ) as SpecimenDealer;
          const id = `${i}`;
          item.id = id;
          this.specimenDealerMap.set(id, item);
        });

        // import speciment dealer objects from Tiled map to Phaser
        const tokenTraders = this.physics.add.staticGroup({
          classType: TokenTrader,
        });
        const tokenTraderLayer = this.map.getObjectLayer("npcTokenTrader");
        tokenTraderLayer.objects.forEach((obj, i) => {
          const item = this.addObjectFromPoint(
            tokenTraders,
            obj,
            "supatokentrader",
            "npcTokenTrader"
          ) as TokenTrader;
          const id = `${i}`;
          item.id = id;
          this.tokenTraderMap.set(id, item);
        });

        // import other objects from Tiled map to Phaser -> use object
        this.addGroupFromTiled(
          "ObjectWallTilesConferenceRoom",
          "tiles_wall_two",
          "PropSprites(2)",
          false
        );

        this.addGroupFromTiled(
          "ObjectMarketplaceWallTiles",
          "tiles_wall_two",
          "PropSprites(2)",
          false
        );

        this.addGroupFromTiled(
          "ObjectMarketPlaceAssets",
          "tiles_wall_two",
          "PropSprites(2)",
          false
        );

        this.addGroupFromTiled(
          "ObjectTileLayer14",
          "tiles_wall_one",
          "PropSprites(1)",
          false
        );

        this.addGroupFromTiled(
          "ObjectTileLayer16",
          "tiles_wall_one",
          "PropSprites(1)",
          false
        );

        this.addGroupFromTiled(
          "ObjectTileLayer12",
          "tiles_wall_two",
          "PropSprites(2)",
          false
        );

        // this.addGroupFromTiled('Wall', 'tiles_wall', 'FloorAndGround', false)
        // this.addGroupFromTiled('Objects', 'office', 'Modern_Office_Black_Shadow', false)
        // this.addGroupFromTiled('ObjectsOnCollide', 'office', 'Modern_Office_Black_Shadow', true)
        // this.addGroupFromTiled('GenericObjects', 'generic', 'Generic', false)
        // this.addGroupFromTiled('GenericObjectsOnCollide', 'generic', 'Generic', true)
        // this.addGroupFromTiled('Basement', 'basement', 'Basement', true)

        // npc microbiologist
        const npcsMapObjects = this.map.objects.find((o) => o.name === "npcs");
        const npcs: any = npcsMapObjects ? npcsMapObjects.objects : [];
        this.npcs = npcs.map((npc) => {
          return new Npc(this, npc.x, npc.y, npc.properties.message);
        });

        // npcs specimen dealer
        const npcSpecimenDealerMapObjects = this.map.objects.find(
          (o) => o.name === "npcSpecimenDealer"
        );
        const npcSpecimenDealer: any = npcSpecimenDealerMapObjects
          ? npcSpecimenDealerMapObjects.objects
          : [];
        this.npcSpecimenDealer = npcSpecimenDealer.map((npc) => {
          return new Npc(this, npc.x, npc.y, npc.properties.message);
        });

        // npcs specimen dealer
        const npcTokenTraderMapObjects = this.map.objects.find(
          (o) => o.name === "npcTokenTrader"
        );
        const npcTokenTrader: any = npcTokenTraderMapObjects
          ? npcTokenTraderMapObjects.objects
          : [];
        this.npcTokenTrader = npcTokenTrader.map((npc) => {
          return new Npc(this, npc.x, npc.y, npc.properties.message);
        });

        this.otherPlayers = this.physics.add.group({ classType: OtherPlayer });

        this.physics.add.collider(
          [this.myPlayer, this.myPlayer.playerContainer],
          groundLayer
        );

        this.physics.add.collider(
          [this.myPlayer, this.myPlayer.playerContainer],
          cautionTapeLayer
        );

        this.physics.add.collider(
          [this.myPlayer, this.myPlayer.playerContainer],
          cautionTape2Layer
        );

        this.physics.add.collider(
          [this.myPlayer, this.myPlayer.playerContainer],
          shadowQuarterLayer
        );

        this.physics.add.collider(
          [this.myPlayer, this.myPlayer.playerContainer],
          shadowHalfLayer
        );

        this.physics.add.collider(
          [this.myPlayer, this.myPlayer.playerContainer],
          wallTilesConferenceRoom4Layer
        );

        this.physics.add.collider(
          [this.myPlayer, this.myPlayer.playerContainer],
          wallTilesConferenceRoom3Layer
        );

        this.physics.add.collider(
          [this.myPlayer, this.myPlayer.playerContainer],
          wallTilesConferenceRoomLayer
        );

        this.physics.add.collider(
          [this.myPlayer, this.myPlayer.playerContainer],
          conferenceRoomAssetsLayer
        );

        this.physics.add.collider(
          [this.myPlayer, this.myPlayer.playerContainer],
          conferenceRoomAssets2Layer
        );

        this.physics.add.collider(
          [this.myPlayer, this.myPlayer.playerContainer],
          marketplaceWallTiles2Layer
        );

        this.physics.add.collider(
          [this.myPlayer, this.myPlayer.playerContainer],
          marketplaceWallTilesLayer
        );

        this.physics.add.collider(
          [this.myPlayer, this.myPlayer.playerContainer],
          marketplaceAssetsLayer
        );

        this.physics.add.collider(
          [this.myPlayer, this.myPlayer.playerContainer],
          marketplaceAssets2Layer
        );

        this.physics.add.collider(
          [this.myPlayer, this.myPlayer.playerContainer],
          tileLayer14
        );

        this.physics.add.collider(
          [this.myPlayer, this.myPlayer.playerContainer],
          tileLayer13
        );

        this.physics.add.collider(
          [this.myPlayer, this.myPlayer.playerContainer],
          loungeTablesLayer
        );

        this.physics.add.collider(
          [this.myPlayer, this.myPlayer.playerContainer],
          tileLayer12
        );

        this.physics.add.collider(
          [this.myPlayer, this.myPlayer.playerContainer],
          tileLayer16
        );

        this.npcSpecimenDealer.map((npc: NpcSpecimenDealer) => {
          this.physics.add.collider(npc, this.myPlayer, npc.talk);
        });

        this.npcTokenTrader.map((npc: NpcTokenTrader) => {
          this.physics.add.collider(npc, this.myPlayer, npc.talk);
        });

        // this.physics.add.collider([this.myPlayer, this.myPlayer.playerContainer], vendingMachines)

        // [chairs, computers, whiteboards, vendingMachines],
        this.physics.add.overlap(
          this.playerSelector,
          [specimenDealers, tokenTraders],
          this.handleItemSelectorOverlap,
          undefined,
          this
        );

        this.physics.add.overlap(
          this.myPlayer,
          this.otherPlayers,
          this.handlePlayersOverlap,
          undefined,
          this
        );

        break;
      // corridor maps
      case MAPS.secondLevel.key:
        const floorTiles2CorridorLayer = this.map.createLayer(
          "Floor Tiles 2",
          FloorAndGround
        );
        floorTiles2CorridorLayer.setCollisionByProperty({ collides: true });

        const shadowCorridorLayer = this.map.createLayer(
          "Shadow",
          FloorAndGround
        );
        shadowCorridorLayer.setCollisionByProperty({ collides: true });

        const wallCorridorLayer = this.map.createLayer("Wall", FloorAndGround);
        wallCorridorLayer.setCollisionByProperty({ collides: true });

        const wall2CorridorLayer = this.map.createLayer(
          "Wall 2",
          FloorAndGround
        );
        wall2CorridorLayer.setCollisionByProperty({ collides: true });

        const cautionTapeCorridorLayer = this.map.createLayer(
          "Caution Tape",
          FloorAndGround
        );
        cautionTapeCorridorLayer.setCollisionByProperty({ collides: true });

        // dataState.playerX ? dataState.playerX : 4600
        // dataState.playerY ? dataState.playerX : 4600

        // 550,
        // 1100,

        // 7100,
        // 2500,

        this.myPlayer = this.add.myPlayer(
          dataState.playerX ? dataState.playerX : 550,
          dataState.playerY ? dataState.playerY : 1100,
          dataState.textureName ? dataState.textureName : "male",
          this.network.mySessionId
        );
        this.playerSelector = new PlayerSelector(this, 0, 0, 256, 512);

        // don't go out of the map
        this.physics.world.bounds.width = this.map.widthInPixels;
        this.physics.world.bounds.height = this.map.heightInPixels;
        this.myPlayer.setCollideWorldBounds(true);

        // import other objects from Tiled map to Phaser -> use object
        this.addGroupFromTiled(
          "ObjectWall",
          "tiles_wall_two",
          "PropSprites(2)",
          false
        );

        this.otherPlayers = this.physics.add.group({ classType: OtherPlayer });

        this.physics.add.collider(
          [this.myPlayer, this.myPlayer.playerContainer],
          groundLayer
        );

        this.physics.add.collider(
          [this.myPlayer, this.myPlayer.playerContainer],
          floorTiles2CorridorLayer
        );

        this.physics.add.collider(
          [this.myPlayer, this.myPlayer.playerContainer],
          shadowCorridorLayer
        );

        this.physics.add.collider(
          [this.myPlayer, this.myPlayer.playerContainer],
          wallCorridorLayer
        );

        this.physics.add.collider(
          [this.myPlayer, this.myPlayer.playerContainer],
          wall2CorridorLayer
        );

        this.physics.add.collider(
          [this.myPlayer, this.myPlayer.playerContainer],
          cautionTapeCorridorLayer
        );

        this.physics.add.overlap(
          this.myPlayer,
          this.otherPlayers,
          this.handlePlayersOverlap,
          undefined,
          this
        );

        break;
      case MAPS.thirdLevel.key:
        const floorTiles2LabLayer = this.map.createLayer(
          "Floor Tiles 2",
          FloorAndGround
        );
        floorTiles2LabLayer.setCollisionByProperty({ collides: true });

        const shadowLabLayer = this.map.createLayer("Shadow", FloorAndGround);
        shadowLabLayer.setCollisionByProperty({ collides: true });

        const wallLabLayer = this.map.createLayer("Wall", FloorAndGround);
        wallLabLayer.setCollisionByProperty({ collides: true });

        const wall2LabLayer = this.map.createLayer("Wall 2", FloorAndGround);
        wall2LabLayer.setCollisionByProperty({ collides: true });

        const wall3LabLayer = this.map.createLayer("Wall 3", FloorAndGround);
        wall3LabLayer.setCollisionByProperty({ collides: true });

        const cautionTapeLabLayer = this.map.createLayer(
          "Caution Tape",
          FloorAndGround
        );
        cautionTapeLabLayer.setCollisionByProperty({ collides: true });

        const cautionTape2LabLayer = this.map.createLayer(
          "Caution Tape 2",
          FloorAndGround
        );
        cautionTape2LabLayer.setCollisionByProperty({ collides: true });

        const tableLabLayer = this.map.createLayer("Table", FloorAndGround);
        tableLabLayer.setCollisionByProperty({ collides: true });

        this.myPlayer = this.add.myPlayer(
          550,
          2900,
          dataState.textureName ? dataState.textureName : "male",
          this.network.mySessionId
        );
        this.playerSelector = new PlayerSelector(this, 0, 0, 256, 512);

        // don't go out of the map
        this.physics.world.bounds.width = this.map.widthInPixels;
        this.physics.world.bounds.height = this.map.heightInPixels;
        this.myPlayer.setCollideWorldBounds(true);

        // import microbiologist objects from Tiled map to Phaser
        const microBiologists = this.physics.add.staticGroup({
          classType: Microbiologist,
        });
        const microBiologistsLayer = this.map.getObjectLayer("npcs");
        microBiologistsLayer.objects.forEach((obj, i) => {
          const item = this.addObjectFromPoint(
            microBiologists,
            obj,
            "microbiologist",
            "npcs"
          ) as Microbiologist;
          const id = `${i}`;
          item.id = id;
          this.microBiologistsMap.set(id, item);
        });

        // import other objects from Tiled map to Phaser -> use object
        this.addGroupFromTiled(
          "ObjectWall",
          "tiles_wall_two",
          "PropSprites(2)",
          false
        );

        this.otherPlayers = this.physics.add.group({ classType: OtherPlayer });

        this.physics.add.collider(
          [this.myPlayer, this.myPlayer.playerContainer],
          groundLayer
        );

        this.physics.add.collider(
          [this.myPlayer, this.myPlayer.playerContainer],
          floorTiles2LabLayer
        );

        this.physics.add.collider(
          [this.myPlayer, this.myPlayer.playerContainer],
          shadowLabLayer
        );

        this.physics.add.collider(
          [this.myPlayer, this.myPlayer.playerContainer],
          wallLabLayer
        );

        this.physics.add.collider(
          [this.myPlayer, this.myPlayer.playerContainer],
          wall2LabLayer
        );

        this.physics.add.collider(
          [this.myPlayer, this.myPlayer.playerContainer],
          wall3LabLayer
        );

        this.physics.add.collider(
          [this.myPlayer, this.myPlayer.playerContainer],
          cautionTapeLabLayer
        );

        this.physics.add.collider(
          [this.myPlayer, this.myPlayer.playerContainer],
          cautionTape2LabLayer
        );

        this.physics.add.collider(
          [this.myPlayer, this.myPlayer.playerContainer],
          tableLabLayer
        );

        this.npcs.map((npc: Npc) => {
          this.physics.add.collider(npc, this.myPlayer, npc.talk);
        });

        // [chairs, computers, whiteboards, vendingMachines],
        this.physics.add.overlap(
          this.playerSelector,
          [microBiologists],
          this.handleItemSelectorOverlap,
          undefined,
          this
        );

        this.physics.add.overlap(
          this.myPlayer,
          this.otherPlayers,
          this.handlePlayersOverlap,
          undefined,
          this
        );

        break;
      default:
        break;
    }
    // register network event listeners
    this.network.onPlayerJoined(this.handlePlayerJoined, this);
    this.network.onPlayerLeft(this.handlePlayerLeft, this);
    this.network.onMyPlayerReady(this.handleMyPlayerReady, this);
    this.network.onMyPlayerVideoConnected(this.handleMyVideoConnected, this);
    this.network.onPlayerUpdated(this.handlePlayerUpdated, this);
    this.network.onItemUserAdded(this.handleItemUserAdded, this);
    this.network.onItemUserRemoved(this.handleItemUserRemoved, this);
    this.network.onChatMessageAdded(this.handleChatMessageAdded, this);
  }

  handleCollissionOutMap(this: any) {
    this.physics.world.bounds.width = this.map.widthInPixels;
    this.physics.world.bounds.height = this.map.heightInPixels;
    this.myPlayer.setCollideWorldBounds(true);
  }

  private handleItemSelectorOverlap(playerSelector, selectionItem) {
    const currentItem = playerSelector.selectedItem as Item;
    // currentItem is undefined if nothing was perviously selected
    if (currentItem) {
      // if the selection has not changed, do nothing
      if (
        currentItem === selectionItem ||
        currentItem.depth >= selectionItem.depth
      ) {
        return;
      }
      // if selection changes, clear pervious dialog
      if (this.myPlayer.playerBehavior !== PlayerBehavior.SITTING)
        currentItem.clearDialogBox();
    }

    // set selected item and set up new dialog
    playerSelector.selectedItem = selectionItem;
    selectionItem.onOverlapDialog();
  }

  private addObjectFromPoint(
    group: Phaser.Physics.Arcade.StaticGroup,
    object: Phaser.Types.Tilemaps.TiledObject,
    key: string,
    tilesetName: string
  ) {
    const actualX = object.x! + object.width! * 0.5;
    const actualY = object.y! - object.height! * 0.5;
    const obj = group.get(actualX, actualY, key, object.gid!).setDepth(actualY);
    return obj;
  }

  private addObjectFromTiled(
    group: Phaser.Physics.Arcade.StaticGroup,
    object: Phaser.Types.Tilemaps.TiledObject,
    key: string,
    tilesetName: string
  ) {
    const actualX = object.x! + object.width! * 0.5;
    const actualY = object.y! - object.height! * 0.5;
    const obj = group
      .get(
        actualX,
        actualY,
        key,
        object.gid! - this.map.getTileset(tilesetName).firstgid
      )
      .setDepth(actualY);
    return obj;
  }

  private addGroupFromTiled(
    objectLayerName: string,
    key: string,
    tilesetName: string,
    collidable: boolean
  ) {
    const group = this.physics.add.staticGroup();
    const objectLayer = this.map.getObjectLayer(objectLayerName);
    objectLayer.objects.forEach((object) => {
      const actualX = object.x! + object.width! * 0.5;
      const actualY = object.y! - object.height! * 0.5;
      group
        .get(
          actualX,
          actualY,
          key,
          object.gid! - this.map.getTileset(tilesetName).firstgid
        )
        .setDepth(actualY);
    });
    if (this.myPlayer && collidable)
      this.physics.add.collider(
        [this.myPlayer, this.myPlayer.playerContainer],
        group
      );
  }

  // function to add new player to the otherPlayer group
  private handlePlayerJoined(newPlayer: IPlayer, id: string) {
    const dataState = store.getState().user;
    const otherPlayer = this.add.otherPlayer(
      newPlayer.x,
      newPlayer.y,
      dataState.textureName ? dataState.textureName : "male",
      id,
      newPlayer.name
    );
    this.otherPlayers.add(otherPlayer);
    this.otherPlayerMap.set(id, otherPlayer);
  }

  // function to remove the player who left from the otherPlayer group
  private handlePlayerLeft(id: string) {
    if (this.otherPlayerMap.has(id)) {
      const otherPlayer = this.otherPlayerMap.get(id);
      if (!otherPlayer) return;
      this.otherPlayers.remove(otherPlayer, true, true);
      this.otherPlayerMap.delete(id);
    }
  }

  private handleMyPlayerReady() {
    this.myPlayer.readyToConnect = true;
  }

  private handleMyVideoConnected() {
    this.myPlayer.videoConnected = true;
  }

  // function to update target position upon receiving player updates
  private handlePlayerUpdated(
    field: string,
    value: number | string,
    id: string
  ) {
    const otherPlayer = this.otherPlayerMap.get(id);
    otherPlayer?.updateOtherPlayer(field, value);
  }

  private handlePlayersOverlap(myPlayer, otherPlayer) {
    otherPlayer.makeCall(myPlayer, this.network?.webRTC);
  }

  private handleItemUserAdded(
    playerId: string,
    itemId: string,
    itemType: ItemType
  ) {
    if (itemType === ItemType.COMPUTER) {
      const computer = this.computerMap.get(itemId);
      computer?.addCurrentUser(playerId);
    } else if (itemType === ItemType.WHITEBOARD) {
      const whiteboard = this.whiteboardMap.get(itemId);
      whiteboard?.addCurrentUser(playerId);
    }
  }

  private handleItemUserRemoved(
    playerId: string,
    itemId: string,
    itemType: ItemType
  ) {
    if (itemType === ItemType.COMPUTER) {
      const computer = this.computerMap.get(itemId);
      computer?.removeCurrentUser(playerId);
    } else if (itemType === ItemType.WHITEBOARD) {
      const whiteboard = this.whiteboardMap.get(itemId);
      whiteboard?.removeCurrentUser(playerId);
    }
  }

  private handleChatMessageAdded(playerId: string, content: string) {
    const otherPlayer = this.otherPlayerMap.get(playerId);
    otherPlayer?.updateDialogBubble(content);
  }

  update(t: number, dt: number) {
    if (this.myPlayer && this.network) {
      this.playerSelector.update(this.myPlayer, this.cursors);
      this.myPlayer.update(
        this.playerSelector,
        this.cursors,
        this.keyE,
        this.keyR,
        this.network
      );
    }
  }

  private initCamera() {
    this.cameras.main.zoom = 0.256;
    this.cameras.main.startFollow(this.myPlayer, true);
    this.cameras.main.roundPixels = true;
  }
}
