export const MAP_CONTENT_KEYS = {
  layers: {
    BACKGROUND: "bg",
  },
  objects: {
    NPCS: "npcs",
    ZONES_LOUNGE: "ObjectLounge",
    ZONES_CORRIDOR: "corridor",
    ZONES_LAB: "ObjectLab",
  },
} as const;
