import Phaser from "phaser";
import MyPlayer from "./MyPlayer";
import { PlayerBehavior } from "../../../types/PlayerBehavior";
import Item from "../items/Item";

export default class PlayerSelector extends Phaser.GameObjects.Zone {
  selectedItem?: Item;
  // private readonly events = new Phaser.Events.EventEmitter();

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    width: number,
    height: number
  ) {
    super(scene, x, y, width, height);

    scene.physics.add.existing(this);
  }

  update(player: MyPlayer, cursors: Phaser.Types.Input.Keyboard.CursorKeys) {
    if (!cursors) {
      return;
    }

    // no need to update player selection while sitting
    if (player.playerBehavior === PlayerBehavior.SITTING) {
      return;
    }

    // update player selection box position so that it's always in front of the player
    const { x, y } = player;
    if (cursors.left?.isDown) {
      this.setPosition(x - 256, y);
    } else if (cursors.right?.isDown) {
      this.setPosition(x + 256, y);
    } else if (cursors.up?.isDown) {
      this.setPosition(x, y - 256);
    } else if (cursors.down?.isDown) {
      this.setPosition(x, y + 256);
      // space pressed with selected item
      // this.events.emit("interaction", this.selectedItem);
      // TODO: show a dialog asking if you want to buy item for price
      // with ok and cancel buttons
    }

    // while currently selecting an item,
    // if the selector and selection item stop overlapping, clear the dialog box and selected item
    if (this.selectedItem) {
      if (!this.scene.physics.overlap(this, this.selectedItem)) {
        this.selectedItem.clearDialogBox();
        this.selectedItem = undefined;
      }
    }
  }
}
