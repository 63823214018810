import Phaser from "phaser";
import { MAPS } from "../constants/maps";
export default class Bootstrap extends Phaser.Scene {
  constructor() {
    super("Boot");
  }

  preload() {
    // load tilemap
    this.loadTileMap();
    // load images
    this.loadImages();
    // load spritesheets
    this.loadSpriteSheets();
    // load audio
    this.loadAudio();
  }

  loadImages() {
    this.load.atlas(
      "cloud_day",
      "assets/background/cloud_day.png",
      "assets/background/cloud_day.json"
    );
    this.load.image("backdrop_day", "assets/background/backdrop_day.png");
    this.load.atlas(
      "cloud_night",
      "assets/background/cloud_night.png",
      "assets/background/cloud_night.json"
    );
    this.load.image("backdrop_night", "assets/background/backdrop_night.png");
    this.load.image("sun_moon", "assets/background/sun_moon.png");
    this.load.image("paperDialog", "assets/items/paper-dialog.png");
    this.load.image("btnPaperDialog", "paper-button-on.png");
  }

  loadSpriteSheets() {
    this.load.spritesheet(
      "tiles_wall_one",
      "assets/map/alpha_v3_3/PropSprites(1).png",
      {
        frameWidth: 256,
        frameHeight: 256,
      }
    );
    this.load.spritesheet(
      "tiles_wall_two",
      "assets/map/alpha_v3_3/PropSprites(2).png",
      {
        frameWidth: 256,
        frameHeight: 256,
      }
    );
    this.load.spritesheet(
      "Shadow_half_tile",
      "assets/map/alpha_v3_3/Shadow_half_tile.png",
      {
        frameWidth: 256,
        frameHeight: 256,
      }
    );
    this.load.spritesheet(
      "Shadow_quartertile",
      "assets/map/alpha_v3_3/Shadow_quartertile.png",
      {
        frameWidth: 256,
        frameHeight: 256,
      }
    );

    // this.load.spritesheet('chairs', 'assets/items/chair.png', {
    //   frameWidth: 32,
    //   frameHeight: 64,
    // })
    // this.load.spritesheet('computers', 'assets/items/computer.png', {
    //   frameWidth: 96,
    //   frameHeight: 64,
    // })
    // this.load.spritesheet('whiteboards', 'assets/items/whiteboard.png', {
    //   frameWidth: 64,
    //   frameHeight: 64,
    // })
    // this.load.spritesheet('vendingmachines', 'assets/items/vendingmachine.png', {
    //   frameWidth: 48,
    //   frameHeight: 72,
    // })
    // this.load.spritesheet('office', 'assets/items/Modern_Office_Black_Shadow.png', {
    //   frameWidth: 32,
    //   frameHeight: 32,
    // })
    // this.load.spritesheet('basement', 'assets/items/Basement.png', {
    //   frameWidth: 32,
    //   frameHeight: 32,
    // })
    // this.load.spritesheet('generic', 'assets/items/Generic.png', {
    //   frameWidth: 32,
    //   frameHeight: 32,
    // })
    // this.load.spritesheet("adam", "assets/character/adam.png", {
    //   frameWidth: 32,
    //   frameHeight: 48,
    // });

    this.load.spritesheet(
      "microbiologist",
      "assets/character/NPCs/microbiologist.png",
      {
        frameWidth: 256,
        frameHeight: 500,
      }
    );
    this.load.spritesheet(
      "specimendealer",
      "assets/character/NPCs/specimendealer.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "supatokentrader",
      "assets/character/NPCs/supatokentrader.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );

    // default
    this.load.spritesheet("male", "assets/character/male.png", {
      frameWidth: 256,
      frameHeight: 512,
    });
    this.load.spritesheet("female", "assets/character/female.png", {
      frameWidth: 256,
      frameHeight: 500,
    });
    // default

    // playable character male | 111 - 133
    this.load.spritesheet(
      "111male",
      "assets/character/Playable_Character_Male/111male.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "112male",
      "assets/character/Playable_Character_Male/112male.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "113male",
      "assets/character/Playable_Character_Male/113male.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "121male",
      "assets/character/Playable_Character_Male/121male.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "122male",
      "assets/character/Playable_Character_Male/122male.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "123male",
      "assets/character/Playable_Character_Male/123male.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "131male",
      "assets/character/Playable_Character_Male/131male.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "132male",
      "assets/character/Playable_Character_Male/132male.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "133male",
      "assets/character/Playable_Character_Male/133male.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    // playable character male | 111 - 133

    // playable character male | 211 - 233
    this.load.spritesheet(
      "211male",
      "assets/character/Playable_Character_Male/211male.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "212male",
      "assets/character/Playable_Character_Male/212male.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "213male",
      "assets/character/Playable_Character_Male/213male.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "221male",
      "assets/character/Playable_Character_Male/221male.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "222male",
      "assets/character/Playable_Character_Male/222male.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "223male",
      "assets/character/Playable_Character_Male/223male.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "231male",
      "assets/character/Playable_Character_Male/231male.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "232male",
      "assets/character/Playable_Character_Male/232male.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "233male",
      "assets/character/Playable_Character_Male/233male.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    // playable character male | 211 - 233

    // playable character male | 311 - 333
    this.load.spritesheet(
      "311male",
      "assets/character/Playable_Character_Male/311male.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "312male",
      "assets/character/Playable_Character_Male/312male.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "313male",
      "assets/character/Playable_Character_Male/313male.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "321male",
      "assets/character/Playable_Character_Male/321male.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "322male",
      "assets/character/Playable_Character_Male/322male.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "323male",
      "assets/character/Playable_Character_Male/323male.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "331male",
      "assets/character/Playable_Character_Male/331male.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "332male",
      "assets/character/Playable_Character_Male/332male.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "333male",
      "assets/character/Playable_Character_Male/333male.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    // playable character male | 311 - 333

    /**
     * playable character female ---------------------------------------------------------------------
     */

    // playable character male | 111 - 133
    this.load.spritesheet(
      "111female",
      "assets/character/Playable_Character_Female/111female.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "112female",
      "assets/character/Playable_Character_Female/112female.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "113female",
      "assets/character/Playable_Character_Female/113female.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "121female",
      "assets/character/Playable_Character_Female/121female.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "122female",
      "assets/character/Playable_Character_Female/122female.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "123female",
      "assets/character/Playable_Character_Female/123female.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "131female",
      "assets/character/Playable_Character_Female/131female.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "132female",
      "assets/character/Playable_Character_Female/132female.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "133female",
      "assets/character/Playable_Character_Female/133female.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    // playable character male | 111 - 133

    // playable character male | 211 - 233
    this.load.spritesheet(
      "211female",
      "assets/character/Playable_Character_Female/211female.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "212female",
      "assets/character/Playable_Character_Female/212female.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "213female",
      "assets/character/Playable_Character_Female/213female.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "221female",
      "assets/character/Playable_Character_Female/221female.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "222female",
      "assets/character/Playable_Character_Female/222female.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "223female",
      "assets/character/Playable_Character_Female/223female.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "231female",
      "assets/character/Playable_Character_Female/231female.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "232female",
      "assets/character/Playable_Character_Female/232female.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "233female",
      "assets/character/Playable_Character_Female/233female.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    // playable character male | 211 - 233

    // playable character male | 311 - 333
    this.load.spritesheet(
      "311female",
      "assets/character/Playable_Character_Female/311female.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "312female",
      "assets/character/Playable_Character_Female/312female.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "313female",
      "assets/character/Playable_Character_Female/313female.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "321female",
      "assets/character/Playable_Character_Female/321female.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "322female",
      "assets/character/Playable_Character_Female/322female.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "323female",
      "assets/character/Playable_Character_Female/323female.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "331female",
      "assets/character/Playable_Character_Female/331female.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "332female",
      "assets/character/Playable_Character_Female/332female.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    this.load.spritesheet(
      "333female",
      "assets/character/Playable_Character_Female/333female.png",
      {
        frameWidth: 256,
        frameHeight: 512,
      }
    );
    // playable character male | 311 - 333
  }

  loadAudio() {
    // audio made with wav
    this.load.audio("backgroundMusic", [
      "assets/audio/supamusic.ogg",
      "assets/audio/supamusic.mp3",
    ]);
    this.load.audio("walk", [
      "assets/audio/effect/walking.ogg",
      "assets/audio/effect/walking.mp3",
    ]);
  }

  loadTileMap() {
    // map made with Tiled in JSON format
    // 1
    this.load.tilemapTiledJSON(
      MAPS.firstLevel.key,
      `assets/map/alpha_v3_3/${MAPS.firstLevel.file}`
    );
    // 2
    this.load.tilemapTiledJSON(
      MAPS.secondLevel.key,
      `assets/map/alpha_v3_3/${MAPS.secondLevel.file}`
    );
    // 3
    this.load.tilemapTiledJSON(
      MAPS.thirdLevel.key,
      `assets/map/alpha_v3_3/${MAPS.thirdLevel.file}`
    );
  }

  create() {
    this.scene.start("bootstrap");
  }
}
